const images = [
  {
    src: "/images/617659.jpg",
    text: "14/04/2019"
  },
  {
    src: "/images/617674.jpg",
    text: "You're my light"
  },
  {
    src: "/images/617675.jpg",
    text: "Night out!"
  },
  {
    src: "/images/617676.jpg",
    text: "I love you!"
  },
  {
    src: "/images/617677.jpg",
    text: "Park!"
  },
  {
    src: "/images/617678.jpg",
    text: "Crazy Night!"
  },
  {
    src: "/images/617679.jpg",
    text: "My Everything"
  },
  {
    src: "/images/617680.jpg",
    text: "Air Kisses!"
  },
  {
    src: "/images/617681.jpg",
    text: "Moving day"
  },
  {
    src: "/images/617682.jpg",
    text: "ฉันสวย <3"
  },
  {
    src: "/images/617683.jpg",
    text: "Everywhere Together"
  },
  {
    src: "/images/617684.jpg",
    text: "Dream come true"
  },
  {
    src: "/images/617685.jpg",
    text: "All in your hands"
  },
  {
    src: "/images/617686.jpg",
    text: "Salcombe!"
  },
  {
    src: "/images/617687.jpg",
    text: "I'm yours!"
  },
  {
    src: "/images/617688.jpg",
    text: "Mini Heart ❤️"
  },
  {
    src: "/images/617689.jpg",
    text: "You're sooo beatiful!"
  },
  {
    src: "/images/617690.jpg",
    text: "Park Kisses!"
  },
  {
    src: "/images/617691.jpg",
    text: 'Professional!'
  },
  {
    src: "/images/617692.jpg",
    text: "Brighton :D"
  },
  {
    src: "/images/617693.jpg",
    text: "พี่จ๋า ฉันหนาว"
  },
  {
    src: "/images/617694.jpg",
    text: "Cheers!"
  },
  {
    src: "/images/617695.jpg",
    text: "Bookstore!"
  },
  {
    src: "/images/617696.jpg",
    text: "Double Hats ;)"
  },
  {
    src: "/images/617697.jpg",
    text: "Let's go~"
  },
  {
    src: "/images/617698.jpg",
    text: "Hahaha~"
  },
  {
    src: "/images/617699.jpg",
    text: "You love me ><"
  },
  {
    src: "/images/617700.jpg",
    text: "Badasses!"
  },
  {
    src: "/images/617701.jpg",
    text: "Big Ben"
  },
  {
    src: "/images/617702.jpg",
    text: "Chinatown"
  },
  {
    src: "/images/617703.jpg",
    text: "So sweet~"
  },
  {
    src: "/images/617704.jpg",
    text: "Baby girl~"
  },
  {
    src: "/images/617705.jpg",
    text: "I'm a monster!"
  },
  {
    src: "/images/617706.jpg",
    text: "Evening walks"
  },
  {
    src: "/images/617707.jpg",
    text: "Stunning!"
  },
  {
    src: "/images/617708.jpg",
    text: "Dressed up!"
  },
  {
    src: "/images/617709.jpg",
    text: "Home Cooking!"
  },
  {
    src: "/images/617710.jpg",
    text: "Pink Gin!"
  },
  {
    src: "/images/617711.jpg",
    text: "14/04/2020"
  }
]

export default images
