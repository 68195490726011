import React, { useState } from 'react'
import { motion } from 'framer-motion'

import './Poloroid.scss'

const Poloroid = ({ image, text, rotation = '-4deg', top, left, ...rest }) => {
  const [x, setX] = useState(left)
  const [y, setY] = useState(top)
  const [zIndex, setZIndex] = useState(0)

  return (
    <motion.div
      className='poloroid-paper'
      style={{
        top: `${y}px`,
        left: `${x}px`,
        zIndex
      }}
      drag
      {...rest}
    >
      <div className='poloroid-image' style={{ backgroundImage: `url(${image})`}} />
      {/* <img src={image} className='poloroid-image' alt={text} /> */}

      <div className='poloroid-text-container'>
        <div className='poloroid-text' style={{ transform: `rotate(${rotation})` }}>
          {text}
        </div>
      </div>
    </motion.div>
  )
}

export default Poloroid
