import React, { useState, useEffect, useRef } from 'react'
import './App.css'

import Poloroid from './components/Poloroid'

import images from './images'

const poloroids = [
  {
    src: 'https://source.unsplash.com/random/310x310',
    text: 'I love you!'
  },
  {
    src: 'https://source.unsplash.com/random/310x310',
    text: 'DanBow <3'
  },
  {
    src: '/images/617659.jpg',
    text: '14/04/2019'
  },
  {
    src: '/images/617659.jpg',
    text: 'Eternity!'
  },
  {
    src: '/images/617659.jpg',
    text: 'คู่ชีวิต'
  }
]

function App() {
  const [{ width, height }, setDimensions] = useState({})

  const ref = useRef(null)
  useEffect(() => {
    const width = ref.current ? ref.current.offsetWidth : 0
    const height = ref.current ? ref.current.offsetHeight : 0

    setDimensions({ width, height })
  }, [])

  return (
    <div className='App' ref={ref}>
      {
        width && images.map((image, index) => {
          return (
            <Poloroid
              image={`${image.src}?picture=${index}`}
              text={image.text}
              top={parseInt(Math.random() * (height - 210), 10)}
              left={parseInt(Math.random() * (width - 175), 10)}
              whileTap={{
                scale: 1.2,
                zIndex: 10,
                rotate: 7
              }}
              key={index}
            />
          )
        })
      }
    </div>
  )
}

export default App
